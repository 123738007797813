<template>
  <div class="main">
    <!-- loading -->
    <div
      class="loadings"
      v-if="loading"
      v-loading="{ visible: loading, text: loadingtext }"
    ></div>
    <newhead />

    <div class="ny-zhuti">
      <!-- 话术模板的说明 技术报告专用 -->
      <div class="jishubaogaoshuoming" v-show="jsbg.sm && jsbg.sm!=''">
        <div class="jishubaogaoshuoming-title">{{jsbg.title}}</div>
        <div class="jishubaogaoshuoming-con">{{jsbg.sm}}</div>
      </div>

      <div class="ny-neirong">
        <div class="ny-con">
          <div class="tjlw">
            
            <div class="tjlw-title">技术报告</div>
            <el-form
              :label-position="'top'"
              label-width="200px"
              :model="form"
              style="max-width: 800px"
            >
              <el-form-item label="关键词" class="biaotiyouhua-item">
                <el-input
                  v-model="title.bt"
                  placeholder="必填，建议至少3个关键词"
                />
              </el-form-item>
              <el-form-item label="附加资料" class="biaotiyouhua-item">
                <el-input
                  rows="3"
                  type="textarea"
                  v-model="title.changwenben"
                  :placeholder="fujiaziliaoval"
                />
              </el-form-item>
              <el-form-item label="请选择类型">
                <el-radio-group v-model="moban.moban" @change="changemoban">
                  <el-radio
                    v-for="(item, key) in moban.options"
                    :key="key"
                    :value="item.mbid"
                    :label="item.mbname"
                  ></el-radio>
                </el-radio-group>
              </el-form-item>


              <!-- 病例个案 显示-->
              <div v-show="moban.index=='-1'">
                <el-form-item label="选择地区">
                  <el-radio-group v-model="moban2.moban"  @change="changemoban2">
                    <el-radio
                      v-for="(item, key) in moban2.options"
                      :key="key"
                      :value="item.mbid"
                      :label="item.mbname"
                    ></el-radio>
                  </el-radio-group>
                </el-form-item>
                
                <el-form-item label="请选择报告模型" v-if="moban3.options.length>0">
                  <el-radio-group v-model="moban3.moban" @change="changemoban3">
                    <el-radio
                      v-for="(item, key) in moban3.options"
                      :key="key"
                      :value="item.mbid"
                      :label="item.mbname"
                    ></el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>

              
              
                <div class="ckwx-btn">
                  <el-button type="primary" @click="shengchengbiaoti"
                    >生成标题</el-button
                  >
                </div>
                <el-form-item
                  class="titleClass"
                  label="选择标题"
                  v-if="title.titles.length != 0"
                >
                  <el-radio-group v-model="title.title">
                    <el-radio
                      :value="item"
                      v-for="item in title.titles"
                      :key="item"
                      :label="item"
                    ></el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="标题（可编辑修改）">
                  <el-input v-model="title.title" />
                </el-form-item>
              
              

              <div class="tjlw-btns">
                <el-button size="large" type="primary" @click="shengchengbaogao"
                  >生成报告</el-button
                >
                <el-button size="large" plain @click="clickckjg"
                  >查看结果</el-button
                >
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>

    <!-- 付款弹窗 -->
    <el-dialog v-model="dialogTableVisible" title="付费提示" width="500" :close-on-click-modal='false' @close="qingchudingshiqi">
      <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
         <el-image style="width: 200px; height: 200px" :src="saomaurl" :fit="fit" />
        <div style="color:red;font-weight:bold;margin:20px 0 10px 0;font-size:24px">¥ {{price}}</div>
        <div style="margin-bottom:20px">您账户积分不足，请扫码付费完成本次操作</div>
      </div>
    </el-dialog>

    <newfooter />
  </div>
</template>

<script setup>
import {
  Delete,
  Edit,
  Search,
  Share,
  TrophyBase,
  Refresh,
} from "@element-plus/icons-vue";
import { post, get } from "@/utils/index";
import { ElMessage, ElMessageBox } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { filter } from "lodash";

const router = useRouter();

// 付款
let dialogTableVisible=ref(false)
let saomaurl=ref('')
let orderid=ref(0)
let price=ref(0)
let timer=0 //setInterval返回值
// loading
let loading = ref(false);
let loadingtext = ref("生成中，请等待...");
let randsum = 0;
let form = reactive({});

// 保存接口返回的内容
let resdata=reactive([])
let jsbg=reactive({
  title:'',
  sm:'',
})
// 附加资料的说明文字（病例个案时必填，其余选填）
let fujiaziliaoval=ref('选填，请输入附加资料，长度5000字以内')

// 第一层模板
let moban = reactive({
  id: 0, //提交接口的模版
  index:0,//选择模型的时候保存Id，主要区分病例个按，其他
  // constindex:0,//生成标题的时候选择的模型index，(字段：Id)主要区分医学，科普，还有其他
  moban: "",
  options: [
    {
      Id:'-1',
      mbid:'-1',
      mbname:'病例个案',
    }
  ],
});

// 第二层模版（病例个案下的地域）
let moban2=reactive({
  moban: "",
  options: [],
})

// 第三层模版（地域下的模版）
let moban3=reactive({
  moban: "",
  options: [],
})


// 标题
let title = reactive({
  bt: "", //输入的关键词
  changwenben:'',//输入的长文本
  fanhuichangwenben:'',//接口返回的长文本
  keys: "", //接口返回的关键词
  titles:[],//最终获取的选题集合
  title:'',//最终选择的标题
});
// 参考文献
let cankaowenxian = reactive({
  cankaowenxian: "", //40条参考文献
  cankaowenxianjingxuan: "", //典型精选
});
// 潜在规律
let qianzaiguilv = reactive({
  qianzaiguilvs: "", //返回的全部潜在规律
  qianzaiguilv: "", //选取的潜在规律
});


// 获取保存在本地的关键词和附加资料
const getguanjianci=()=>{
  let tempdata=window.localStorage.getItem('shuruneirong_baogao')
  if(tempdata && tempdata!=''){
    title.bt=JSON.parse(tempdata).bt
    title.changwenben=JSON.parse(tempdata).changwenben
  }
}
getguanjianci()

// 获取模板列表
const getmoban = () => {
  get("/api/UserLogin/GetMobanList", {
    typeid: 5,
  }).then((res) => {
    resdata.push(...res.data)
    res.data.forEach(e=>{
      // 需要在第一季菜单显示的模版，地域为空，不参与病例个案的选择
      if(e.dy==''){
        moban.options.push(e);
      }
    })
    
  });
};
getmoban();

// 选择分类下的模版
const changemoban = (val) => {
  // 病例个案显示地域选择
  if(val=='病例个案'){
    fujiaziliaoval.value='必填，请输入附加资料，长度5000字以内'
    // 清空地域
    moban2.options.length=0
    jsbg.sm=''
    resdata.forEach(e=>{
      if(e.dy!=''){
        // 已经存在的地域不在添加
        if(moban2.options.filter(ele=>{return ele.mbname==e.dy}).length==0){
          let opt={}
          opt.mbid=e.mbid
          opt.mbname=e.dy
          moban2.options.push(opt)
        }
      }
    })
  }else{
    fujiaziliaoval.value='选填，请输入附加资料，长度5000字以内'
    // 清空第二季和第三季
    moban2.options.length=0
    moban3.options.length=0
    moban2.moban=''
    moban3.moban=''
  }
  // 常规模版
  moban.id = moban.options.filter((e) => {
    return val == e.mbname;
  })[0].mbid;
  getjsbgsm()
  moban.index=moban.options.filter((e) => {
    return val == e.mbname;
  })[0].Id;
};

// 选择地域
const changemoban2=(val)=>{
  // 清空第三集
  moban3.options.length=0
  let tempdata=resdata.filter(e=>{
    return e.dy==val
  })
  moban3.options.push(...tempdata)
}

// 选择第三季
const changemoban3=(val)=>{
  moban.id = moban3.options.filter((e) => {
    return val == e.mbname;
  })[0].mbid;
  getjsbgsm()
}

// 获取潜在规律
const huoquqianzaiguilv = async () => {
  return post("/api/Paper/GetQzglckwx", {
    kw: title.bt,
    txt: title.changwenben,
    mbid: moban.id,
  })
    .then((res) => {
      title.keys = res.data.kw;
      cankaowenxian.cankaowenxian = res.data.ckwx;
      cankaowenxian.cankaowenxianjingxuan = res.data.dxjx;
      qianzaiguilv.qianzaiguilvs = res.data.qzgl;
      title.fanhuichangwenben = res.data.subtxt
    })
    .catch((err) => {
      loading.value = false;
      return Promise.reject({
        isNotErrorExpection: true,
      });
    });
};

// 默认取潜在规律的第一条
const huoquqianzaiguilvval = async () => {
  return new Promise((resolve, reject) => {
    let tempqianzaiguilv = qianzaiguilv.qianzaiguilvs.replace(/\n\n/g, "\n");
    qianzaiguilv.qianzaiguilv=tempqianzaiguilv.split("\n")[2]
    resolve();
  });
};

// 获取标题
const huoqubiaoti = async () => {
  title.titles.length=0
  return post("/api/Paper/GetTitleByQzglFree", {
    kw: title.bt,
    txt: title.changwenben + title.fanhuichangwenben,
    qbqzgl: qianzaiguilv.qianzaiguilvs,
    qzgl: qianzaiguilv.qianzaiguilv,
    ckwx: cankaowenxian.cankaowenxian,
    dxjx: cankaowenxian.cankaowenxianjingxuan,
    mbid: moban.id,
  })
    .then((res) => {
      // 替换\n\n为\n
      let temptitle2 = res.data.replace(/\n\n/g, "\n");
      title.titles.push(...temptitle2.split("\n"));
      // 默认选中第一条
      title.title=title.titles[1]
    })
    .catch((err) => {
      loading.value = false;
      return Promise.reject({
        isNotErrorExpection: true,
      });
    });
};

// 开始生成标题
const shengchengbiaoti = async () => {
  if(moban.id==0 || moban.id==143 || moban.id==144){
    if (title.bt.trim() == "") {
      ElMessage({
        message: "标题和模型不能为空",
        duration: 1000,
      });
      return;
    }
  }else{
    if (title.bt.trim() == "" || title.changwenben.trim() == "") {
      ElMessage({
        message: "标题、附加资料和模型不能为空",
        duration: 1000,
      });
      return;
    }
  }
  
  // 保存填写的信息到本地
  window.localStorage.setItem('shuruneirong_baogao',JSON.stringify(title))


  loading.value = true;
  loadingtext.value = "正在生成中，当前进度0%，请耐心等待...";
  // 初始化
  randsum = 0;
  let conouttime = 60 + Math.floor(Math.random() * 5) + 1;
  loadingfun(conouttime);
  await huoquqianzaiguilv();
  await huoquqianzaiguilvval();
  await huoqubiaoti();



  loading.value = false;
};

// 生成技术报告
const shengchengbaogao=async()=>{
  
  if (cankaowenxian.cankaowenxian == "") {
    ElMessage({
      message: "请生成标题后再生成报告！",
      duration: 1000,
    });
    return;
  }
  if (title.title.trim() == "") {
    ElMessage({
      message: "标题不能为空",
      duration: 1000,
    });
    return;
  }
  loading.value=true
  loadingtext.value = "正在提交中，请耐心等待...";

  return post('/api/Paper/SubReport', {
    mbid: moban.id,
    ybt:title.bt,
    title:title.title,
    subtxt:title.changwenben + title.fanhuichangwenben,
    kw: title.keys,
    ckwx: cankaowenxian.cankaowenxian,
    dxjx: cankaowenxian.cankaowenxianjingxuan,
    qzgl: qianzaiguilv.qianzaiguilv,
    qbqzgl: qianzaiguilv.qianzaiguilvs, 
    oid:orderid.value,
  })
    .then((res) => {
      loading.value=false
      // 清空
      title.bt = "";
      title.title = "";
      title.titles.length = []
      title.changwenben = "";
      cankaowenxian.cankaowenxian = ""
      // 清空localStorage
      window.localStorage.removeItem('shuruneirong_baogao')
      ElMessageBox.alert(`本次消耗${res.data.price}积分，剩余${res.data.syprice}积分。预计一个工作日内完成审核，结果可在“个人中心-写作记录”中查看。`,
        "提示",
        {
          confirmButtonText: "我知道了",
          callback: () => {
            clickckjg()
          },
        }
      );
      
      
    })
    .catch((err) => {
      loading.value = false;
      // 余额不足调用弹窗付款
      if(err.data.code===210){
        saomaurl.value="data:image/jpeg;base64,"+err.data.data.payimg
        orderid.value=err.data.data.oid
        price.value=err.data.data.price
        dialogTableVisible.value=true
        // 循环调用查询支付状态
        timer = setInterval(()=>{
          zhifuzhuangtai()
        },2000)
      }
      return Promise.reject({
        isNotErrorExpection: true,
      });
    });
}

// 查询支付状态
const zhifuzhuangtai=()=>{
  get("/api/WxPay/GetOrderStatus", {
    id: orderid.value,
  })
    .then((res) => {
      // 200支付成功，211未支付
      if(res.code===200){
        // 注销定时查询
        clearInterval(timer)
        ElMessage({
          message: '支付成功！',
          type: 'success',
          duration:1000,
          onClose:()=>{
            dialogTableVisible.value=false
            // 继续选题
            fukuanlijishengcheng()
          }
        })
      }
    })
}

// 支付后在生成
const fukuanlijishengcheng=async()=>{
  await shengchengbaogao();
  // 清空订单id
  orderid.value=0
}

// 清除定时器和订单id
const qingchudingshiqi=()=>{
  clearInterval(timer)
  orderid.value=0
}

// 查看结果
const clickckjg = () => {
  // 根据最后一篇论文生成时的选择的类型，到相应类型的论文记录列表
  let navindexval='2-3'
  let urltemp='/xiezuojilu'

  window.localStorage.setItem("navindex", navindexval);
  router.push({
    path: urltemp,
    query: {
      id:5,
    },
  });
};

// 获取模版说明
const getjsbgsm=()=>{
  let tempobj=resdata.filter(e=>{
    return e.mbid==moban.id
  })
  if(tempobj.length>0){
    jsbg.sm=tempobj[0].mbdes
    jsbg.title=tempobj[0].mbname
  }
}

// loading
const loadingfun = (outtime) => {
  let randNumtemp = Math.floor(Math.random() * 5) + 1;
  if (loading.value) {
    setTimeout(() => {
      randsum += randNumtemp;
      if (randsum < outtime) {
        let temprandnum = ((randsum / outtime) * 100).toFixed(2);
        loadingtext.value = `正在生成中，当前进度${temprandnum}%，请耐心等待...`;
        loadingfun(outtime);
      } else {
        loadingtext.value = `正在生成中，当前进度99.99%，请耐心等待...`;
      }
    }, randNumtemp * 1000);
  }
};
</script>

<style scoped>
:deep(.titleClass .el-radio-group) {
  align-items: flex-start;
  flex-direction: column;
}
:deep(.titleClass .el-radio) {
  white-space: normal;
  height: auto;
  min-height: 32px;
  line-height: 20px;
  margin: 0 0 0 0;
}
:deep(.el-input-group__prepend .el-button) {
  color: var(--el-button-text-color);
  background-color: var(--el-button-bg-color);
  border-color: var(--el-button-border-color);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
:deep(.el-input-group__append .el-button) {
  color: var(--el-button-text-color);
  background-color: var(--el-button-bg-color);
  border-color: var(--el-button-border-color);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:deep(.el-checkbox) {
  white-space: normal;
}
:deep(.el-form-item__label) {
  font-weight: bold;
}
:deep(.tjlw-btns .el-button) {
  width: 200px;
  height: 50px;
  font-size: 16px;
}
.main {
  width: 100%;
  height: 100%;
  background: #fff;
  min-width: 1600px;
}

.ny-zhuti {
  position: relative;
  width: 100%;
  min-height: 600px;
  height: calc(100% - 100px);
}

/* 流程 */
.ny-liucheng {
  position: absolute;
  left: 200px;
  top: 0;
  width: 400px;
  height: 100%;
  overflow-y: scroll;
  background: url("/img/common/bg-side.9a695b46.png") no-repeat;
  background-size: 100% 100%;
}
.ny-liucheng-title {
  margin-top: 82px;
  margin-bottom: 56px;
  font-size: 28px;
  color: #48a4ff;
  text-align: center;

  opacity: 1;
}
.ny-liucheng-li-title {
  width: 320px;
  height: 38px;
  background: linear-gradient(
    270deg,
    rgba(218, 249, 248, 0.6),
    rgba(179, 225, 251, 0.6)
  );
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid hsla(0, 0%, 100%, 0.51);
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding-left: 12px;
  font-weight: bold;
}
.ny-liucheng-li {
  width: 320px;
  height: auto;
  font-size: 14px;
  background: hsla(0, 0%, 100%, 0.3);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid hsla(0, 0%, 100%, 0.6);
  margin: 15px auto 20px;
}
.ny-liucheng-li-title-icon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: linear-gradient(147deg, #48b6ff, #4891ff);
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  margin-right: 12px;
}

.ny-neirong {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.ny-nav {
  width: 1200px;
  height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #888;
}

.ny-nav a:link,
.ny-nav a:visited {
  color: #888;
  text-decoration: none;
}
.ny-nav a:hover {
  color: #2489f2;
}
.ny-con {
  width: 1200px;
  height: auto;
  background: #fff;
  padding: 30px;
  margin: auto;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.tjlw {
  width: 800px;
  margin: auto;
}
.tjlw-title {
  font-size: 30px;
  font-weight: 400;
  color: #3f4654;
  line-height: 42px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.tjlw-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tjlw-img-txt {
  position: absolute;
  left: calc(50% - 400px);
  top: 36px;
  z-index: 1;
  width: 800px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.biaotiyouhua-item {
  position: relative;
}
.biaotiyouhua {
  position: absolute;
  left: 0;
  top: 35px;
  width: 800px;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
  font-size: 12px;
  background: #fff;
  box-shadow: 0 6px 20px 1px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 1px solid #ececee;
  z-index: 10;
}
.biaotiyouhua-title {
  display: flex;
  align-items: center;
}
.biaotiyouhua-bt {
  padding: 2px 0;
}
.biaotiyouhua-bts {
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: 4px;
  cursor: pointer;
}
.biaotiyouhua-bts:hover {
  background: #f1f5ff;
  color: #2489f2;
}
.biaotiyouhua-hyp {
  width: 70px;
  cursor: pointer;
  padding: 0 0 0 20px;
  display: flex;
  align-items: center;
  color: #2489f2;
}
.tjlw-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 20px 0;
}
.cankaowx {
  width: 800px;
  height: 328px;
  overflow: hidden;
  padding: 6px 20px;
  box-sizing: border-box;
  font-size: 12px;
  background: #f7f7f7;
  border-radius: 4px;
}
.cankaowx2 {
  width: 800px;
  height: auto;
  padding: 6px 20px;
  box-sizing: border-box;
  font-size: 12px;
  background: #f7f7f7;
  border-radius: 4px;
}
.cankaowx-more {
  width: 100%;
  font-size: 14px;
  text-align: center;
}
.cankaowx-more > span {
  cursor: pointer;
  color: #e6a23c;
}
.cankaowx-more > span:hover {
  text-decoration: underline;
}

.loadings {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
}
.tjlw-btns-link {
  margin-left: 40px;
  font-size: 14px;
  color: #2489f2;
  cursor: pointer;
}
.tjlw-btns-link:hover {
  text-decoration: underline;
}
.ckwx-btn {
  padding: 10px 0 20px 0;
}
.tips{
  color: #999;
  font-size: 14px;
}

.jishubaogaoshuoming{
  position: absolute;
  left: calc(50% + 420px);
  top: 300px;
  z-index: 1;
  width: 360px;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  border: 1px solid #dddfe5;
  border-radius: 2px;
  font-size: 14px;
}
.jishubaogaoshuoming-title{
  text-align: center;
  font-weight: bold;
  padding-bottom: 10px;
}


/* 美化滚动条 */
::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

::-webkit-scrollbar-track {
  width: 6px;
  background: rgba(#101f1c, 0.1);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.1);
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  transition: background-color 0.3s;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144, 147, 153, 0.3);
}
</style>
